<template>
  <div>
    <CCardHeader> {{ cases.length }} Active files </CCardHeader>
    <br />
    <horizontal-scroll>
      <data-table v-bind="bindings" />
    </horizontal-scroll>
  </div>
</template>

<script>
import DataTable from "@andresouzaabreu/vue-data-table";
// import "bootstrap/dist/css/bootstrap.min.css";
import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

import ActionButton from "../components/ActionButton.vue";

import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    DataTable,
    ActionButton,
    HorizontalScroll,
  },
  data() {
    return {
      cases: [],
    };
  },
  computed: {
    bindings() {
      return {
        columns: [
          {
            key: "file_reference",
            title: "Reference",
            sortable: true,
          },
          {
            title: "Actions",
            sortable: false,
            component: ActionButton,
          },
          {
            key: "pri_lawyer",
            title: "Pri User",
            sortable: true,
          },
          {
            key: "bring_up_date_pri",
            title: "Pri BU",
            // sortable: true,

            sortingFunction: function(a, b) {
              var da = new Date(a.bring_up_date_pri);
              var db = new Date(b.bring_up_date_pri);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "sec_lawyer",
            title: "Sec User",
            sortable: true,
          },
          {
            key: "bring_up_date_sec",
            title: "Sec BU",
            sortable: true,

            sortingFunction: function(a, b) {
              var da = new Date(a.bring_up_date_sec);
              var db = new Date(b.bring_up_date_sec);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "client_name",
            title: "Client",
            sortable: true,
          },
          {
            key: "category",
            title: "Category",
            sortable: true,
          },
          {
            key: "date_opened",
            title: "Date Opened",
            sortable: true,
            sortingFunction: function(a, b) {
              var da = new Date(a.date_opened);
              var db = new Date(b.date_opened);

              return da.getTime() - db.getTime();
            },
          },
          {
            key: "matter_summary",
            title: "Summary",
            sortable: false,
          },
        ],
        data: this.cases,
        showDownloadButton: false,
        sortingMode: "single",
        perPageSizes: [5, 10, 25, 50],
        defaultPerPage: 5,
        /* other props...*/
      };
    },
  },
  mounted() {
    this.fetch_active_cases();
  },
  methods: {
    fetch_active_cases() {
      const axios = require("axios");

      this.loading = true;

      var vm = this;

      axios
        .get("/cases/active")
        .then(function(res) {
          // handle success
          vm.cases = res.data;
          console.log(vm.cases);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },
  },
};
</script>
